import type { PropsWithChildren } from "react"
import Svg24Logo from "./icons/24Logo"
import classNames from "classnames"
import type { Maybe } from "~/types/core"

interface LayoutProps extends PropsWithChildren {
  title: string
  helpMessage: Maybe<string | JSX.Element>
}

export default function Layout({ title, helpMessage, children }: LayoutProps) {
  return (
    <section className="bg-stone min-h-screen h-full w-full flex items-center justify-center p-4">
      <div
        className={classNames(
          "bg-white shadow-menu w-full max-w-100 rounded-sm",
          helpMessage && "pb-8",
        )}
      >
        <div className="h-28 flex items-center justify-center border-b-thin border-black/15 mb-6">
          <Svg24Logo height={56} width={56} />
        </div>
        <div className="px-8 flex flex-col items-center">
          <h1 className="font-avenir font-medium text-center text-lg text-body-copy leading-8 mb-6">
            {title}
          </h1>
          {children}
          {helpMessage && (
            <>
              <hr className="w-28 mb-6 border-0 border-b-thin border-black/15" />
              <span className="text-body-copy leading-7">{helpMessage}</span>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
